@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
    height: 100%;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

:root {
    --pink-clr: #FB3099;
}

.model__img {
    max-width: 20rem;
    width: 100%;
}

.xray__logo {
    max-width: 8rem;
    width: 100%;
    margin: 0 auto;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-height: 400px) {
    .header {
        position: relative;
    }
}

@media (max-width: 640px) {
    .header {
        padding: 1rem;
    }
}